<template>
    <div class=" -mt-[10px]" :class="aloneClass">
        <div class="flex items-center mb-[12px] 2xl-1440:mb-[5px] h-[43px]">
            <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mr-2">Sin</p>
            <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[30px] mt-1" />
        </div>
        <div class="border-separation-mid"></div>
        <div class="grid grid-cols-4 gap-4 px-[60px]">
            <!-- N° de habitaciones -->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">N° de habitaciones</label>
                <input type="number" v-model.number="store.rooms" class="input-field-total no-arrow text-right mr-4" />
            </div>

            <!-- Precio medio por noche -->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Precio medio<br> por noche</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="store.averagePrice" step="0.01" class="input-field-total no-arrow text-right " />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!-- Tasa de ocupación -->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Tasa de ocupación</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="store.occupancyRate" step="0.01" class="input-field-total no-arrow text-right " />
                    <p class="input-suffix">%</p>
                </div>
            </div>

            <!-- Total de ingresos anuales -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label text-[20px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Total de ingresos <br>anuales</label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] h-full">
                    <p class="input-field">{{ formattedAnnualRevenue }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-12" :class="aloneClass">
        <div class="flex items-center mb-[12px] 2xl-1440:mb-[5px] h-[43px]">
            <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mr-2">Con</p>
            <img src="/img/presentacion/LogoPNG.png" alt="Logo" class="h-[30px] mt-1" />
        </div>
        <div class="border-separation-mid"></div>
        <p class="text-[24px] font-semibold mb-[6px] 2xl-1440:text-[20px] h-[40px] px-[60px]">Ingresos</p>
        <div class="grid grid-cols-4 gap-4 px-[60px]">
            <!-- Incremento en la reputación online -->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Incremento en la <br>reputación online</label>
                <input type="number" v-model.number="store.reputationIncrease" step="0.01" class="input-field-total no-arrow text-right" />
                <p class="input-suffix">%</p>
            </div>

            <!-- Incremento en el precio medio por noche -->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Nuevo precio <br>medio por noche</label>
                <div class="flex items-center rounded-r-full">
                    <input type="number" v-model.number="store.pricePerNightIncrease" step="0.01" class="input-field-total no-arrow text-right no-padding-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!-- Incremento en la tasa de ocupación -->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Nueva <br>tasa de ocupación</label>
                <div class="flex items-center rounded-r-full">
                    <input type="number" v-model.number="store.occupancyRateIncrease" step="0.01" class="input-field-total no-arrow text-right no-padding-right" />
                    <p class="input-suffix">%</p>
                </div>
            </div>

            <!-- Total de ingresos anuales con TheHoster -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label text-[18px] 2xl-1440:text-[13px] w-3/5 flex flex-col">
                    Total de Ingresos<br>
                    <p class="flex items-center whitespace-nowrap">
                        anuales con
                        <img src="/img/presentacion/LogoSmallPNG.png" alt="Logo" class="ml-1 mt-0 w-[95px] h-[20px] inline-block align-middle" />
                    </p>
                </label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] h-full">
                    <p class="input-field">{{ formattedAnnualRevenueWithHoster }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>

        </div>
        <p class="text-[24px] font-semibold mb-[6px] mt-[16px] 2xl-1440:text-[20px] h-[35px] px-[60px]">Costes</p>
        <div class="flex gap-4 px-[60px]">
            <!-- Precio por habitación al mes -->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Precio por habitación al mes</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="form.pricePerRoomPerMonth" step="0.01" class="input-field-total no-arrow text-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!--Precio de implementación-->
            <div class="flex items-center bg-[#FAFAFA] border border-black rounded-full-style">
                <label class="input-label-black  text-[18px] 2xl-1440:text-[13px] w-3/5 whitespace-nowrap">Precio de implementación</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="form.implementationPrice" step="0.01" class="input-field-total no-arrow text-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!-- Inversión en TheHoster -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow w-[30%]">
                <label class="input-label text-[18px] 2xl-1440:text-[13px] w-3/5 flex items-center whitespace-nowrap">
                    Inversión en
                    <img src="/img/presentacion/LogoSmallPNG.png" alt="Logo" class="ml-1 mt-0 w-[95px] h-[20px] inline-block align-middle" />
                </label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px]  h-full">
                    <p class="input-field">{{ formattedInvestmentInHoster }}</p>
                    <span class="input-suffix">€</span>
                </div>
            </div>


        </div>
        <!-- Retorno Section -->
        <div class="mt-[48px] 2xl-1440:mt-[38px] flex flex-col items-center w-full">
            <div class="flex flex-col w-full max-w-[1680px]">
                <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mb-[12px] self-start w-full max-w-[500px]">Retorno</p>
                <hr class="border-t-[3px] border-[#34A98F] w-full max-w-[1680px] mb-[40px]" />
                <div class="grid grid-cols-3 gap-[124px] w-full max-w-[1680px] mx-auto mt-[0px]">
                    <!-- Incremento en la facturación -->
                    <div class="flex justify-between items-center w-full">
                        <label class="text-left text-[24px] font-semibold">Incremento en<br> la facturación</label>
                        <span class="text-[#1E6659] font-semibold text-[48px] leading-none">{{ formattedIncrement > 0 || formattedIncrement == 0 ? '+' : '' }}{{ formattedIncrement ?? 0 }}€</span>
                    </div>

                    <!-- Beneficio -->
                    <div class="flex justify-between items-center w-full">
                        <label class="text-left text-[24px] font-semibold">Beneficio</label>
                        <span class="text-[#1E6659] font-semibold text-[48px] leading-none">{{ formattedBenefit > 0 || formattedBenefit == 0 ? '+' : '' }}{{ formattedBenefit ?? 0 }}€</span>
                    </div>

                    <!-- ROI -->
                    <div class="flex justify-between items-center w-full">
                        <label class="text-left text-[24px] font-semibold">Retorno</label>
                        <span class="text-[#1E6659] font-semibold text-[48px] leading-none">{{ formattedROI ?? 0 }}x</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, computed, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useCalculatorStore } from '../stores/useCalculatorStore';

const store = useCalculatorStore();

const route = useRoute();

const aloneClass = computed(() => {
  return route.meta.alone ? 'px-[60px]' : '';
});

const form = reactive({
    rooms: store.rooms,
    averagePrice: store.averagePrice,
    occupancyRate: store.occupancyRate,
    annualRevenue: store.annualRevenue,
    // ingresos
    reputationIncrease: store.reputationIncrease,
    occupancyRateIncrease: store.occupancyRateIncrease,
    pricePerNightIncrease: store.pricePerNightIncrease,
    annualRevenueWithHoster: '',
    // costes
    pricePerRoomPerMonth: 8.99,
    implementationPrice: 900,
    investmentInHoster: 0, 
    benefit: 0 
});

// Cálculo de "Total de ingresos anuales"
const annualRevenue = computed(() => {
    return Math.ceil(store.rooms * store.averagePrice * (store.occupancyRate / 100) * 365 || 0);
});

const formattedAnnualRevenue = computed(() => {
    return formatCurrency(annualRevenue.value);
});


const annualRevenueWithHoster = computed(() => {
    if (
        store.rooms !== null &&
        store.pricePerNightIncrease !== null &&
        store.occupancyRateIncrease !== null
    ) {
        return Math.ceil(
            store.rooms * store.pricePerNightIncrease *
            (store.occupancyRateIncrease / 100) *
            365 || 0
        );

    }
    return 0;
});


const formattedAnnualRevenueWithHoster = computed(() => {
    return formatCurrency(annualRevenueWithHoster.value);
});

// Cálculo de "Incremento en la facturación"
const increment = computed(() => {
    return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value);
});

const formattedIncrement = computed(() => {
    return formatCurrency(increment.value);
});


watch(
    () => [store.rooms, form.pricePerRoomPerMonth, form.implementationPrice],
    ([rooms, pricePerRoomPerMonth, implementationPrice]) => {
        if (rooms && pricePerRoomPerMonth !== null) {
            form.investmentInHoster = Math.ceil(rooms * pricePerRoomPerMonth * 12 + (implementationPrice || 0));
        } else {
            form.investmentInHoster = 0;
        }
    },
    { immediate: true }
);

const formattedInvestmentInHoster = computed(() => {
    return formatCurrency(form.investmentInHoster);
});

// Cálculo del beneficio
const benefit = computed(() => {
    return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value - form.investmentInHoster) || 0;
});

const formattedBenefit = computed(() => {
    return formatCurrency(benefit.value);
});

// Cálculo del ROI
const roi = computed(() => {
    const roiValue = ((annualRevenueWithHoster.value - annualRevenue.value - form.investmentInHoster) / form.investmentInHoster);
    if (isNaN(roiValue) || roiValue === Infinity || roiValue < 0) {
        return 0;
    }
    return roiValue;
});

const formattedROI = computed(() => {
    return roi.value.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
});

// Formatear el número como moneda con puntos
function formatCurrency(value) {
    return value.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }); // Eliminando los decimales
}

// Usamos un watch para actualizar los valores en form
watch(annualRevenue, (newValue) => {
    form.annualRevenue = newValue;
});

watch(annualRevenueWithHoster, (newValue) => {
    form.annualRevenueWithHoster = newValue;
});

watch(benefit, (newValue) => {
    form.benefit = newValue;
});




</script>

<style scoped>
@import url('ttps://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&display=swap');
/* Eliminar las flechas del input de tipo número */
.no-arrow::-webkit-inner-spin-button,
.no-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
label,span,p {
  font-family: 'Rethink Sans', sans-serif !important;
}


.no-arrow {
  -moz-appearance: textfield;
}

/* Sombra personalizada */
.custom-shadow {
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
}

/* Redondeo completo aplicado en el contenedor */
.rounded-full-style {
    border-radius: 10px;
    height: 70px;
    
}

.input-label-black {
    padding: 15px 11px;
    font-weight: 600;
    color: #333333;
    font-size: 18px;
    height: 100%;
    background-color: #FFF;
    text-align: left;
    border-right: 1px solid black;
    border-radius: 10px 0 0 10px;
    display: flex; /* Asegura que el contenido esté en un contenedor flex */
    align-items: center; /* Centra el texto verticalmente */
}


/* Estilo general para los labels y los inputs */
.input-label {
    padding: 12px 11px;
    font-size: 18px;
    height: 70px;
    font-weight: 600;
    color: #333333;
    text-align: left;
    border-right: 1px solid #E9E9E9;

    border-radius: 9999px 0 0 9999px;
    font-family: 'Rethink Sans', sans-serif !important;
}

.input-black-label-border {
    border-right: 1px solid black !important;
}

/* Clase específica para labels más anchos */
.wider-label {
    width: 56%; /* Ajusta este ancho según lo necesites */
}

.wider-label-max {
    width: 60%; /* Ajusta este ancho según lo necesites */
}

.wider-label-max-return {
    width: 80%; /* Ajusta este ancho según lo necesites */
}

.wider-label-max-increment {
    width: 80%; /* Ajusta este ancho según lo necesites */
}

/* Asegurar que el label no se rompa en varias líneas */
.whitespace-nowrap {
    white-space: nowrap;
}

.border-bottom-custom {
    border-bottom: 4px solid #34A98F;
}

.input-field {
    padding: 15px 0; /* Eliminado el padding derecho */
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    width: 100%;
    border-radius: 0 9999px 9999px 0;
    border: none;
    text-align: right;
    font-family: 'Rethink Sans', sans-serif !important;
}

.input-field-total {
    padding: 15px 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    width: 100%;
    border-radius: 0 9999px 9999px 0;
    background-color: #FAFAFA;
    text-align: right;
    font-family: 'Rethink Sans', sans-serif !important;
}

.input-field-total-return {
    font-size: 20px;
    font-weight: 600;
    line-height: 130%;
    width: 100%;
    text-align: right;
    color: white;
    font-family: 'Rethink Sans', sans-serif !important;
}

/* Clase adicional para eliminar el padding derecho */
.no-padding-right {
    padding-right: 0;
}

.input-suffix {
    padding-left: 12px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 600;
    margin-left: -12px; /* Ajuste para compensar el padding del input */
    color: #333333;
    font-family: 'Rethink Sans', sans-serif !important;
}

.input-suffix-return {
    padding-left: 12px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 500;
    margin-left: -12px; /* Ajuste para compensar el padding del input */
    color: white;
    font-family: 'Rethink Sans', sans-serif !important;
}

/* Eliminar el borde y sombra en el focus del input */
input:focus {
    border: none !important;
    box-shadow: none !important;
}

.border-separation-mid {
    background-color: #333333;
    height: 3px;
    margin-bottom: 16px;
}

.border-separation-roi {
    background-color: #34A98F;
    height: 3px;
    margin-bottom: 16px;
}

</style>

